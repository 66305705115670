import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Impressum from './pages/Impressum';
import Beitrag from "./pages/Beitrag";
import Beitreage from "./pages/Beitreage";
import Aktuelles from './pages/Aktuelles';
import Stories from './pages/Stories';
import Error from './components/Error';
import Admin from './pages/Admin';
import Login from './components/Login';
import LandingPageEdit from './components/admin/LandingPageEdit';
import ReviewEdit from "./components/admin/ReviewEdit";
import ImpressumEdit from './components/admin/ImpressumEdit';
import BlogEdit from './components/admin/BlogEdit';

import "./styles/global/bootstrap.min.css";
import "./styles/global/font-awesome.min.css";
import './index.css';
import "./styles/global/animate.css";
import "./styles/global/ionicons.min.css";
import "./styles/global/responsive.css";
import "./styles/global/pe-icon.min.css";
import "./styles/global/colors.css"

import {createBrowserRouter, RouterProvider} from "react-router-dom"
import ErfolgsgeschichtenEdit from './components/admin/ErfolgsgeschichtenEdit';
import BlogsEdit from './components/admin/BlogsEdit';
import AktuellesEdit from './components/admin/AktuellesEdit';
import Special from './pages/Special';
import SpecialSiteEdit from './components/admin/SpecialSiteEdit';
import CategorieEdit from './components/admin/CategoriesEdit';
import SettingsEdit from './components/admin/SettingsEdit';

async function loader(){
  const headers = new Headers()
  headers.append("Content-Type", "application/json")

  const requestOptions = {
      method: "GET",
      headers: headers,
  }

 return fetch(`${process.env.REACT_APP_BACKEND}/home`, requestOptions)
      .then((response) => response.json())
      .then((data)=>{
        return data
      })
      .catch((error)=>{
          console.log(error)
      })
  }


const router = createBrowserRouter([
  {
    path: "/",
    loader: loader,
    errorElement: <Error/>,
    element: <App/>
  }
  ,{
    path:"/impressum",
    element:<Impressum/>
  },
  {
    path:"/beitrag/:id",
    element:<Beitrag></Beitrag>
  },
  {
    path:"/aktuelles",
    element:<Aktuelles></Aktuelles>
  },
  {
    path:"/beitreage",
    element:<Beitreage></Beitreage>
  },
  ,
  {
    path:"/stories",
    element:<Stories></Stories>
  },
  {
    path: "/special",
    element: <Special />,
    errorElement: <Error/>
  },
  {
  path: "/admin",
  element: <Admin />,
  errorElement: <Error />,
  children: [
    {
      index: true, 
      element: <Login/>, 
    },
    {
      path: "/admin/landingpage",
      element: <LandingPageEdit/>,
    },
    {
      path: "/admin/erfolgsgeschichten",
      element: <ErfolgsgeschichtenEdit/>,
    },
    {
      path: "/admin/blog",
      element: <BlogsEdit/>,
    },
    {
      path:"/admin/blog/:id",
      element:<BlogEdit></BlogEdit>
    },
    {
      path: "/admin/aktuelles",
      element: <AktuellesEdit/>,
    },
    {
      path: "/admin/reviews",
      element: <ReviewEdit/>
    },
    {
      path: "/admin/impressum",
      element: <ImpressumEdit/>
    },
    {
      path: "/admin/special",
      element: <SpecialSiteEdit/>
    },
    {
      path:"/admin/categories",
      element:<CategorieEdit></CategorieEdit>
    },
    {
      path:"/admin/settings",
      element:<SettingsEdit></SettingsEdit>
    }
  ]
  }
])

ReactDOM.createRoot(document.getElementById("root")).render(
    
    <RouterProvider router={router} />
 
);
