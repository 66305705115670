import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { handleSubmitEvent } from "../../utils/handlers";
import Input from "../parts/Input";

export default function SettingsEdit() {
  const [settings, setSettings] = useState();
  const { jwtToken } = useOutletContext("");

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/admin/");
      return;
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
      credentials: "include"
    };

    fetch(`${process.env.REACT_APP_BACKEND}/admin/settings`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setSettings(data[0]);
      })
      .catch((error) => {
        Swal.fire({
          title: "Fehler!",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }, []);

  let errors = [];
  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const handleChange = () => (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setSettings({
      ...settings,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  return (
    settings && (
      <>
        <form
          onSubmit={handleSubmitEvent(
            jwtToken,
            settings,
            false,
            "/admin/settings",
            "PATCH",
            () => {
              navigate(0);
            }
          )}
        >
          <div>
            <button
              className="mb-10 p-2 btn btn-primary"
              onClick={() => {
                navigate(-1);
              }}
            >
              Zurück
            </button>
            <hr />
            <h2 className="mt-50">Einstellungen</h2>
            <Input
              title={"Shop Link"}
              className={"form-control"}
              type={"text"}
              name={"shop_link"}
              value={settings.shop_link}
              onChange={handleChange("shop_link")}
              errorDiv={hasError("shop_link") ? "text-danger" : "d-none"}
              errorMsg={"Bitte einene Text eingeben"}
            ></Input>

            <Input
              title={"Email Password"}
              className={"form-control"}
              type={"password"}
              name={"email_password"}dd
              value={settings.email_password}
              onChange={handleChange("email_password")}
              errorDiv={hasError("email_password") ? "text-danger" : "d-none"}
              errorMsg={"Bitte einene Text eingeben"}
            ></Input>
             <Input
              title={"Email"}
              className={"form-control"}
              type={"password"}
              name={"email"}dd
              value={settings.email}
              onChange={handleChange("email")}
              errorDiv={hasError("email") ? "text-danger" : "d-none"}
              errorMsg={"Bitte einene Text eingeben"}
            ></Input>
          </div>

          <div>
            <button type="submit" className="btn btn-primary m-3">
              Speichern
            </button>
            <a href="/admin/landingpage" className="btn btn-danger ms-2">
              Abbrechen
            </a>
          </div>
        </form>
      </>
    )
  );
}
